import React, { useEffect, createRef } from "react"
import { StaticQuery, graphql } from "gatsby"
import parse from 'html-react-parser'
import Navigation from "../components/navigation"
import CTAThankYou from "../components/utility/cta_thank_you"
import lottie from "lottie-web/build/player/lottie_light.min.js"
import AnimImage from "../assets/images/animations/404.json"
import Frame from "../components/common/frame"

function Error404() {
  // const [showAnim, setShowAnim] = useState(false)
  // const [refAnim, inViewAnim] = useInView({
  //   triggerOnce: true,
  //   threshold: 0,
  // })

  let animationPlatformContainer = createRef()
  useEffect(() => {
    // if (showAnim === false) {
    //   if (inViewAnim === true) {
    //     setShowAnim(true)
    //   }
    // }

    const animPlatform = lottie.loadAnimation({
      container: animationPlatformContainer.current,
      renderer: "svg",
      loop: false,
      autoplay: true,
      animationData: AnimImage,
    })

    return () => animPlatform.destroy()
  })

  return (
    <>
      <StaticQuery
        query={graphql`
          query content404 {
            SuperOps {
              pages(where: { title: "404" }) {
                title
                pageBlock {
                  id
                  content {
                    html
                  }
                }
                navigationBlock {
                  name
                  slug
                  isDropdown
                }
                seo {
                  title
                  description
                  keywords
                  image {
                    url
                  }
                }
              }
            }
          }
        `}
        render={data => (
          <div className="ab">
            {data.SuperOps.pages.map(page => {
              const { title, pageBlock, navigationBlock, seo } = page
              return (
                <>
                  <Frame
                    seo={seo}
                  >
                    <header>
                      <Navigation links={navigationBlock} page={title} />
                    </header>

                    <section className="nf-main">
                      <center>
                        {parse(pageBlock[0].content.html)}

                        <center>
                          <div>
                            {/* <div ref={refAnim}> */}
                            <div
                              className="404-animation"
                              ref={animationPlatformContainer}
                            />
                          </div>
                        </center>

                        {parse(pageBlock[1].content.html)}

                        {parse(pageBlock[2].content.html)}
                      </center>
                    </section>

                    <CTAThankYou />
                  </Frame>
                </>
              )
            })}
          </div>
        )}
      />
    </>
  )
}

export default Error404
